/* You can add global styles to this file, and also import other style files */
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@font-face {
  font-family: 'Garamond';
  src: url(assets/fonts/Garamond.ttf) format("opentype");
}


html, body {
  min-height: 100vh;
  height: auto;
  margin: 0;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #ebebeb;
}


.form-group {
  display: block;
  width: 100%;
  position: relative;
}

ngx-file-drop.input-upload-file {
  .ngx-file-drop__drop-zone {
    border: 2px dashed rgba(53, 95, 169,.3) !important;
    min-height: 250px !important;
    background-color: rgba(53, 95, 169,.1);
    border-radius: 4px !important;
    text-align: center;
  }

  .ngx-file-drop__content {
    flex-direction: column;
    position: relative !important;
    height: 100% !important;
    top: 0 !important;

    p {
      font-size: 16px;
      color: var(--primary-color) !important;
      font-weight: bold;
      line-height: 26px;
    }

    .texts-limits {
      position: absolute;
      bottom: 5px;
      left: 5px;
      color: #444;
      font-weight: 100;
      font-size: 12px;

      b {
        font-weight: 500;
      }
    }
  }
}

.editor-product .NgxEditor__Content {
  min-height: 300px;
}

.mat-input-rounded {
  .mdc-notched-outline__leading {
    border-radius: 28px 0 0 28px !important;
    min-width: 28px !important;
  }

  .mdc-notched-outline__trailing {
    border-radius: 0 28px 28px 0 !important;
  }
}

.mat-button-rounded {
  border-radius: 20px !important;
}


.input-number-without-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


mat-chip.product-status, mat-chip.order-status {
  text-transform: capitalize;

  &.product-created, &.order-shipped {
    background-color: #2ecc71;
    .mdc-evolution-chip__text-label {
      color: #fff;
    }
  }

  &.product-approved, &.order-created {
    background-color: #3498db;
    .mdc-evolution-chip__text-label {
      color: #fff;
    }
  }

  &.product-review_in_progress, &.order-pending {
    background-color: #f1c40f;
    .mdc-evolution-chip__text-label {
      color: #fff;
    }
  }

  &.product-changes_required, &.order-confirmed {
    background-color: #9b59b6;
    .mdc-evolution-chip__text-label {
      color: #fff;
    }
  }

  &.product-changes_applied {
    background-color: #9b59b6;
    .mdc-evolution-chip__text-label {
      color: #fff;
    }
  }

  &.product-rejected, &.order-canceled {
    background-color: #e74c3c;
    .mdc-evolution-chip__text-label {
      color: #fff;
    }
  }

  &.product-paused, &.order-received {
    background-color: #34495e;
    .mdc-evolution-chip__text-label {
      color: #fff;
    }
  }

  &.product-out_of_stock {
    background-color: #95a5a6;
    .mdc-evolution-chip__text-label {
      color: #fff;
    }
  }

  &.product-disabled {
    background-color: #95a5a6;
    .mdc-evolution-chip__text-label {
      color: #fff;
    }
  }

  &.product-sold {
    background-color: #95a5a6;
    .mdc-evolution-chip__text-label {
      color: #fff;
    }
  }

}

.modal-product .mat-mdc-dialog-content.mdc-dialog__content {
  padding: 0;
  max-height: 100vh;
  max-width: 100vw !important;

  app-product-detail .container {
    padding: 0;

    .product-container {
      box-shadow: none;
    }
  }
}

.modal-messages .mat-mdc-dialog-surface.mdc-dialog__surface {
  border-radius: 20px;
}

.policies-container {
  padding: 30px 40px;
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;

  embed {
    width: 100%;
    height: 400px;
  }

  .pdf-viewer {
    width: 100%;
    height: 50vh;

    // X-Large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
      height: 80vh;
    }
  }

}

.snackbar-vtc {
  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    padding: 16px 20px;
  }

  &.snackbar-success {
    .mdc-snackbar__surface {
      background-color: #27ae60;
    }
  }

  &.snackbar-error {
    .mdc-snackbar__surface {
      background-color: #e74c3c;
    }
  }

  &.snackbar-warning {
    .mdc-snackbar__surface {
      background-color: #e67e22;
    }
  }
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  &.chip-success {
    background-color: #27ae60;

    .mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
      color: #fff;
    }
  }

  &.chip-warning {
    background-color: #e67e22;

    .mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
      color: #fff;
    }
  }
}

.button-icon-action {
  mat-icon {
    color: #e74c3c;
  }
}

.admin-table {
  background-color: #b2bec3;
  font-weight: bolder;
  font-size: 16px;
}

.vtc-action {
  background-color: black !important;
  color: white !important;
}

.vtc-secondary-action {
  background-color: #636e72 !important;
  color: white !important;
}

.custom-scrollbar {

  /* For Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    height: 10px;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  /* For firefox */
  scrollbar-width: thin;
  scrollbar-color: #ccc #ffffff;
}

lib-ngx-image-zoom .ngxImageZoomContainer {
  cursor: zoom-in;
}

lib-ngx-image-zoom .ngxImageZoomContainer .ngxImageZoomThumbnail {
  max-width: 250px;

  @media (min-width: 576px) {
    max-width: 100px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    max-width: 200px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    max-width: 240px;
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    max-width: 300px;
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1420px) {
    max-width: 400px;
    max-height: 600px;
  }
}


.files-container {
  display: block;
  width: 100%;
}

.input-upload-file-container {
  display: block;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #fff;

  &:hover {
    border: 1px solid rgba(0, 0, 0, .4);
  }

  > .row {
    min-height: 40px;
  }

  .image-thumbnail {
    display: block;
    width: 100px;
    text-align: center;

    img {
      max-width: 100px;
      max-height: 100px;
    }
  }

  .file-icon {

    mat-icon {
      width: 30px;
      height: 30px;
      font-size: 30px;
      color: #4eba7b;
    }
  }


  .file-name {
    text-decoration: none;

    p {
      margin: 0;
    }

    span {
      font-size: 12px;
      color: #888;
    }

  }
}


.cdk-drag.cdk-drag-preview.file-container {
  display: block;
  width: 100%;

  img {
    max-width: 100px;
  }
}

.chip-error {
  .mat-mdc-chip-ripple {
    background-color: #e74c3c;
  }

  .mat-mdc-chip-action-label {
    color: #fff !important;
  }
}

.table-scroll {
  overflow-x: scroll;
}
